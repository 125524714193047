import React from 'react'
import PageApi from '../components/pages/Api/Api'
import Layout from '../Layouts/zh-hk'

const apiZhHK = ({ location }) => {
  return (
    <Layout location={location}>
      <PageApi />
    </Layout>
  )
}

export default apiZhHK
